import Vue from "vue";
import VueRouter from "vue-router";
import { cryptrGuard } from "../CryptrGuard";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: cryptrGuard,
    // route level code-splitting
    // this generates a separate chunk (profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
